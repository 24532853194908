<template>
  <div>
      <div class="jumbotron">
          <div class="row">
              <div class="col"></div>
          </div>
      </div>
      <div class="row justify-content-center align-items-center">
          <div class="col-md-8 content m-4 text-center">
               <h1>404</h1> 
               <h2>PAGE NOT FOUND</h2>
          </div>
      </div>
  </div>
</template>

<script>
import AppBar from '../components/appBar'
import FinalFooter from '../components/FinalFooter'
import url from '../helpers/url'
import serviceurl from '../helpers/service'
export default {
    components:{
        FinalFooter,
        AppBar
    },
    
}
</script>

<style>
.jumbotron{
    margin-top:-85px;
    background:#ba2428;
    height: 200px;
}
.content{
    margin:150px 0;
}
.myBtn{
    background-color:#ba2428;
    padding:1em 2em;
    color:#fff;
    text-decoration: none;
}
h1{
    font-size: 15em;
}
@media screen and (max-width:600px) {
    h1{
        font-size: 8em;
    }
}
</style>